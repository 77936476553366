// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anos-mdx-frontmatter-variant-mdx-slug-js": () => import("./../../../src/pages/anos/{mdx.frontmatter__variant}/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-anos-mdx-frontmatter-variant-mdx-slug-js" */),
  "component---src-pages-calculadora-js": () => import("./../../../src/pages/calculadora.js" /* webpackChunkName: "component---src-pages-calculadora-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

